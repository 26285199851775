body {
    background-image: linear-gradient(150deg, #00a8b0 0%, #78be20 100%);
    background-size: cover;
    font-size: 16px;
    min-height: 100vh;
    position: relative;
}

.loginForm {
    background: rgba(255, 255, 255, 0.85) none repeat scroll 0 0;
    border-radius: 8px;
    margin: 20px auto;
    padding: 20px;
    width: 95%;
}

.login-box {
    margin: 30px auto 0;
}

.mandatory_field {
	color: red;
}

.container__main {
    /* flex: 1 1 0; */
    flex: 1;
    min-height: calc(100vh - 64px);
    padding-bottom: 120px;
    position: relative;
}

.loginForm h3,
.registerForm h3 {
    color: #2e8355;
    margin-bottom: 15px;
}

.text-center {
    text-align: center;
}


/*buttons*/

.btn {
    background: #dadada none repeat scroll 0 0;
    border: 1px solid transparent;
    font-size: 16px;
    font-weight: bold !important;
}

.btn-primary {
    background-color: #2e8355 !important;
    border-color: #2e8355 !important;
}

.btn-default {
    background-color: transparent !important;
    border-color: #2e8355 !important;
    border-width: 2px !important;
    color: #2e8355 !important;
}


/*form controls*/

.control-label {
    padding-top: 7px;
    margin-bottom: 0;
    text-align: right;
}

.form-control {
    border-color: #50b680;
    font-size: 16px;
}

.form-control:focus {
    box-shadow: none !important;
    border-color: #50b680 !important;
}

.alertSummary {
    background-color: #ffffff;
    border-radius: 4px;
    color: #000000;
    font-size: 16px;
    margin: 30px auto 20px;
    padding: 12px 10px 12px 52px;
    position: relative;
    width: 95%;
}

.alertSummary::before {
    background: rgba(0, 0, 0, 0) url(src/assets/img/ic_alert.svg) no-repeat scroll 0 0 / contain;
    content: "";
    display: inline-block;
    height: 20px;
    left: 24px;
    position: absolute;
    top: 14px;
    vertical-align: middle;
    width: 20px;
}

.alertSummary.info::before {
    background: rgba(0, 0, 0, 0) url(src/assets/img/ic_info.svg) no-repeat scroll 0 0;
    content: "";
    height: 18px;
    width: 18px;
}

@media only screen and (max-width: 943px) {
    .alertSummary.info::before {
        height: 15px;
        width: 15px;
    }
}

@media only screen and (min-width: 944px) {
    .alertSummary.info {
        color: #222222;
    }
}

.header {
    background-color: rgba(255, 255, 255, 0.2) !important;
    box-shadow: 0 1px 8px 0 rgb(51 51 51 / 40%);
}

.header a {
    color: #ffffff;
}

.main_container .box h3 {
    color: #ffffff;
    margin: 20px;
}

.main_container .box .content {
    background: rgba(255, 255, 255, 0.65) none repeat scroll 0 0;
    border-radius: 8px;
    margin: 20px auto;
    padding: 35px 28px 20px;
    width: 95%;
}


/* table styles */

.table>thead>tr>th {
    vertical-align: top !important;
    border-bottom: 2px solid rgba(137, 213, 182, 0.35) !important;
    font-weight: bold;
    color: #757575;
    border-bottom: 0px;
}

.pointer-cursor {
    cursor: pointer;
}


/* responsive */

@media (min-width:768px) {
    .login-box {
        width: 400px;
    }
}

@media (max-width:768px) {
    .btn.resend-btn {
        margin-top: 10px;
    }
}

@media (min-width:576px) {
    .form-horizontal .form-group {
        text-align: right;
    }
}

/* loading when call API */
.modal {
    display:    none;
    position:   fixed;
    z-index:    11000;
    top:        0;
    left:       0;
    height:     100%;
    width:      100%;
    background: rgba( 255, 255, 255, .6 ) 
                url('./assets/img/ic_loading.svg') 
                50% 50% 
                no-repeat;
}

/* When the body has the loading class, we turn
   the scrollbar off with overflow:hidden */
body.loading {
    overflow: hidden;
}

/* Anytime the body has the loading class, our
   modal element will be visible */
body.loading .modal {
    display: block;
}